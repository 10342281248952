<template>
  <div v-if="Object.keys(admin_scenario_issue).length !== 0">
    <div class="">
      <b-modal id="admin-scenario-issue-status-modal" title="Admin Scenario Issue Status" size="xl" hide-footer>
        <div class="modal-content bg-white rounded">
          <div class="card">
            <div class="card-body">
              <a-steps :current="current" size="small" status="wait">
                <a-step title="Issue Raised" :description="admin_scenario_issue.admin_scenario_issue_statuses.filter(e => e.status === 'ISSUE_PENDING').length > 0 ? customDate(admin_scenario_issue.admin_scenario_issue_statuses.find(e => e.status === 'ISSUE_PENDING').created_at) : ''" />
                <a-step title="Issue in Progress" v-if="admin_scenario_issue.admin_scenario_issue_status.includes('ISSUE_IN_PROGRESS_BY_SOLVER') && !admin_scenario_issue.admin_scenario_issue_status.includes('ISSUE_SOLVED_BY_SOLVER') && !admin_scenario_issue.admin_scenario_issue_status.includes('ISSUE_RETURN_BY_SOLVER')" :description="admin_scenario_issue.admin_scenario_issue_statuses.filter(e => e.status === 'ISSUE_IN_PROGRESS_BY_SOLVER').length > 0 ? customDate(admin_scenario_issue.admin_scenario_issue_statuses.find(e => e.status === 'ISSUE_IN_PROGRESS_BY_SOLVER').created_at) : ''"  />
                <a-step class="ant-steps-item-error" title="Issue Rejected From Solver" v-else-if="admin_scenario_issue.admin_scenario_issue_status.includes('ISSUE_RETURN_BY_SOLVER') && !admin_scenario_issue.admin_scenario_issue_status.includes('ISSUE_IN_PROGRESS_BY_SOLVER') && !admin_scenario_issue.admin_scenario_issue_status.includes('ISSUE_SOLVED_BY_SOLVER')" :description="admin_scenario_issue.admin_scenario_issue_statuses.filter(e => e.status === 'ISSUE_RETURN_BY_SOLVER').length > 0 ? customDate(admin_scenario_issue.admin_scenario_issue_statuses.find(e => e.status === 'ISSUE_RETURN_BY_SOLVER').created_at) : ''" >
                  <a-icon slot="icon" type="close-circle" />
                </a-step>
                <a-step title="Issue in Progress" v-else :description="admin_scenario_issue.admin_scenario_issue_statuses.filter(e => e.status === 'ISSUE_IN_PROGRESS_BY_SOLVER').length > 0 ? customDate(admin_scenario_issue.admin_scenario_issue_statuses.find(e => e.status === 'ISSUE_IN_PROGRESS_BY_SOLVER').created_at) : ''"  />
                <a-step title="Issue Solved"  :description="admin_scenario_issue.admin_scenario_issue_statuses.filter(e => e.status === 'ISSUE_SOLVED_BY_SOLVER').length > 0 ? customDate(admin_scenario_issue.admin_scenario_issue_statuses.find(e => e.status === 'ISSUE_SOLVED_BY_SOLVER').created_at) : ''" />
                <a-step title="Issue Verified"  :description="admin_scenario_issue.admin_scenario_issue_statuses.filter(e => e.status === 'ISSUE_CONFIRMED_BY_ISSUER').length > 0 ? customDate(admin_scenario_issue.admin_scenario_issue_statuses.find(e => e.status === 'ISSUE_CONFIRMED_BY_ISSUER').created_at) : ''" />
              </a-steps>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'status',
  props: ['current', 'admin_scenario_issue'],
  data() {
    return {}
  },
  methods: {
    customDate(date) {
      return moment(date).format('LLL')
    },
  },
}
</script>

<style scoped>
.ant-steps-item-description {
  margin-top: -5px !important;
}
</style>
